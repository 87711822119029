<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <h1 class="display-2 font-weight-light">Please wait...</h1>
            </v-col>
        </v-row>
        <v-row justify="center"  class="py-5">
            <v-col style="text-align: center">
                 <p class="headline">We are redirecting you to <a href="https://cryptium.com">cryptium.com</a></p>
                 <p class="headline">Please <a href="https://cryptium.com/contact/">contact us</a> to share your thoughts or ask questions</p>
            </v-col>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<style scoped>
p {
    color: #777;
}
p a {
    color: #0080ff;
}
</style>

<script>
import SectionLayout from '@/components/SectionLayout.vue';

export default {
    components: {
        SectionLayout,
    },
    mounted() {
        window.location = 'https://cryptium.com';
    },
};
</script>
